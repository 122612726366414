<template>
    <v-container grid-list-xl fluid id="micuenta">
        <alert v-bind:tipo="notificacion.tipo" v-bind:mensaje="notificacion.mensaje"></alert>
        <v-layout row wrap>
            <v-flex xs12 sm8 md6 v-if="CantidadComponentes">
                <template v-for="(item,i) in Componentes">
                    <component :is="item" v-bind:key="i"></component>
                </template>
            </v-flex>
            <v-flex xs12 sm8 md6>
                <v-form ref="form" v-on:submit.prevent="Guardar">
                    <v-card>
                        <v-toolbar color="cyan" dark flat dense card>
                            <v-toolbar-title class="subheading">Actualizar Contraseña</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-text-field
                                label="Nombre de usuario"
                                autocomplete="username"
                                v-model="username"
                                disabled
                                type="text"
                            ></v-text-field>
                            <v-text-field
                                label="Contraseña Actual"
                                required
                                autocomplete="current-password"
                                v-model="contActual"
                                :rules="contActualRules"
                                type="password"
                            ></v-text-field>
                            <v-text-field
                                label="Nueva Contraseña"
                                required
                                autocomplete="new-password"
                                v-model="contNueva"
                                :rules="contNuevaRules"
                                type="password"
                            ></v-text-field>
                            <v-text-field
                                label="Repetir Contraseña"
                                required
                                v-model="contNuevaRep"
                                autocomplete="new-password"
                                :rules="contRepetirRules"
                                type="password"
                            ></v-text-field>
                        </v-card-text> 
                        <v-card-actions>
                            <v-btn type="submit" block color="cyan" dark :loading="isloading">Guardar</v-btn>
                        </v-card-actions>      
                    </v-card>
                </v-form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import alert from '@/components/alert/alert.vue'
import datosResponsable from '@/components/ctacte/datosResponsable.vue';
import userService from '@/services/UserServices';
export default {
    name: 'MiCuenta',
    components: {datosResponsable, alert},
    data() {
        return {
            Componentes: [],
            isloading: false,
            contActual: '',
            contNueva: '',
            contNuevaRep: '',
            notificacion: {mensaje: '',tipo: 'error'},
            username: Object.values(userService.current())[0],
            contActualRules: [
                (v) => !!v || 'Ingrese su contraseña actual.'
            ],
            contNuevaRules: [
                (v) => !!v || 'Ingrese una nueva contraseña.',
                (v) => v.length >= 6 || 'Ingrese una contraseña con 6 o más caracteres.'
            ],
            contRepetirRules: [
                (v) => v == this.contNueva || 'Las contraseñas no son iguales.'
            ]
        }
    },
    created() {
        this.CargarComponentes();
    },
    computed: {
        CantidadComponentes(){
            return this.Componentes.length;
        }
    },
    methods: {
        CargarComponentes() {
            this.Componentes = [];
            if (!userService.IsAdmin()) {
                this.Componentes [0] = 'datosResponsable';
            }
        },
        Guardar() {
            /* Verificamos que se cumplan las validaciones */
            if (this.$refs.form) {        
                if (!this.$refs.form.validate()) {
                return false; 
                }
            }
            this.isloading = true;
            userService.CambiarPass(this.contActual, this.contNueva, this.contNuevaRep)
            .then(() => {
                this.contActual = '';
                this.contNueva = '';
                this.contNuevaRep = '';
                this.notificacion.tipo = 'success';
                this.notificacion.mensaje = 'Contraseña actualizada correctamente.';
                this.$refs.form.resetValidation();
            })
            .catch(err => {
                this.notificacion.tipo = 'error';
                this.notificacion.mensaje = 'Ocurrio un error al intentar actualizar contraseña.'
                if(err.response && err.response.data.hasOwnProperty('errorMessage')){
                    this.notificacion.mensaje = err.response.data.errorMessage;
                }
            })
            .finally(() => {
                this.isloading = false
            });
        }
    }
}
</script>
